<template>
  <div class="detail">
    <h2>{{ company.companyName }}</h2>
    <div class="basic">
      <div class="title">
        企业基本信息
        <div class="bg"></div>
      </div>
      <div class="name">
        <span>企业名称:</span>
        <span>{{ company.companyName }}</span>
      </div>
    </div>
    <div class="permission">
      <div class="title">
        电影公映许可证
        <div class="bg"></div>
      </div>
      <ul v-if="permissionList.length > 0">
        <li v-for="(item, index) in permissionList" :key="index">
          <div class="top">
            <div class="name">
              <span class="movie-name">{{ item.name }}</span>
              <span class="movie-type">{{ item.filmType }}</span>
            </div>
            <div class="company">{{ item.dycpdw }}</div>
          </div>
          <div class="bottom">
            <div>
              <span>编码:</span>
              <span>{{ item.code }}</span>
            </div>
            <div>
              <span>公映证号:</span>
              <span>{{ item.gyzh }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="no-data">暂无数据</div>
    </div>
    <div class="record">
      <div class="title">
        电影备案立项
        <div class="bg"></div>
      </div>
      <ul class="record-list" v-if="recordList.length > 0">
        <li v-for="(item, index) in recordList" :key="index">
          <div class="top">
            <div class="name">
              <span class="movie-name">{{ item.filmName }}</span>
              <span class="record-name">{{ item.balxh }}</span>
            </div>
            <div
              class="dw"
              v-for="(dwitem, dwindex) in item.badw"
              :key="dwindex"
            >
              <i></i>
              <span>{{ dwitem }}</span>
            </div>
            <div class="type">{{ item.type }}</div>
          </div>
          <div class="bottom">
            <div>
              <span>备案地:</span>
              <span>{{ item.bad }}</span>
            </div>
            <div>
              <span>公示时间:</span>
              <span v-if="item.gssj">{{ item.gssj.slice(0, 7) }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="no-data">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api/index'

export default {
  name: 'companyDetail',
  data() {
    return {
      companyId: '',
      company: {},
      permissionList: [],
      recordList: [],
    }
  },
  mounted() {
    this.companyId = this.$route.query.id
    this.getDetail()
    this.getPermissionList()
    this.getRecordList()
  },
  methods: {
    //获取公司详情
    getDetail() {
      api.Company.getCompanyByID(this.$route.query.id).then((res) => {
        this.company = res
      })
      // this.$axios({
      //   apiUrl: "/ycc/query/companyByID",
      //   url: "",
      //   method: "GET",
      //   data: {
      //     id: this.$route.query.id,
      //   },
      // }).then((res) => {
      //   this.company = res.result;
      // });
    },
    //获取公映许可证列表
    getPermissionList() {
      api.Company.getCompanyLicenseList({ companyId: this.companyId }).then(
        (res) => {
          this.permissionList = res
        },
      )
      // this.$axios({
      //   apiUrl: "/ycc/query/filmGyxkz/byCompany",
      //   url: "",
      //   method: "GET",
      //   data: {
      //     companyId: this.$route.query.id,
      //   },
      // }).then((res) => {
      //   this.permissionList = res.result;
      // });
    },
    //获取备案立项列表
    getRecordList() {
      api.Company.getCompanyBALXList({ companyId: this.companyId }).then(
        (res) => {
          res.forEach((item) => {
            item.badw = item.badw.split('、')
          })
          this.recordList = res
        },
      )
      // this.$axios({
      //   apiUrl: "/ycc/query/filmBags/byCompany",
      //   url: "",
      //   method: "GET",
      //   data: {
      //     companyId: this.$route.query.id,
      //   },
      // }).then((res) => {
      //   res.result.forEach((item) => {
      //     item.badw = item.badw.split("、");
      //   });
      //   this.recordList = res.result;
      // });
    },
  },
}
</script>

<style lang="less" scoped>
.no-data {
  background: white;
  text-align: center;
  font-size: 14px;
  color: gray;
  padding: 15px 0;
  margin-top: 20px;
}
.detail {
  width: 100%;
  background: #f6f7f8;
  padding: 12px 16px;
  box-sizing: border-box;
  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    color: #363a44;
  }
  .basic {
    margin-top: 30px;

    .name {
      width: 100%;
      background: #fff;
      border-radius: @border-radius-base;
      margin-top: 30px;
      padding: 12px;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #363a44;
      }
    }
  }
  .permission {
    margin-top: 30px;
    ul {
      margin-top: 30px;
      li {
        width: 100%;
        background: #ffffff;
        border-radius: @border-radius-base;
        margin-bottom: 12px;
        .top {
          padding: 12px;
          box-sizing: border-box;
          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            .movie-name {
              font-size: 16px;
              font-weight: 500;
              color: #363a44;
            }
            .movie-type {
              padding: 4px 10px;
              background: #ebf3fe;
              border-radius: 2px;
              font-size: 12px;
              font-weight: 500;
              color: #244e8a;
            }
          }
          .company {
            font-size: 12px;
            font-weight: 400;
            color: #686b73;
          }
        }
        .bottom {
          min-height: 40px;
          border-radius: 0px 0px 4px 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          border-top: 1px solid #f6f7f8;
          & > div {
            flex: 1;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #686b73;
            }
          }
        }
      }
    }
  }
  .record {
    margin-top: 30px;
    ul {
      margin-top: 30px;
      li {
        width: 100%;
        background: #ffffff;
        border-radius: @border-radius-base;
        margin-bottom: 12px;
        .top {
          padding: 12px;
          box-sizing: border-box;
          position: relative;
          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            .movie-name {
              font-size: 16px;
              font-weight: 500;
              color: #363a44;
            }
            .record-name {
              font-size: 12px;
              font-weight: 400;
              color: #428ffc;
            }
          }
          .dw {
            i {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              border: 1px solid #428ffc;
              margin-right: 5px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #363a44;
            }
          }
          .type {
            position: absolute;
            top: 40px;
            right: 12px;
            padding: 4px 10px;
            background: #ebf3fe;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 500;
            color: #244e8a;
          }
        }
        .bottom {
          height: 40px;
          background: url('../../../assets/info/itembg.png') no-repeat;
          background-size: cover;
          border-radius: 0px 0px 4px 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          & > div {
            flex: 1;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #686b73;
            }
          }
        }
      }
    }
  }
  .title {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #244e8a;
    .bg {
      height: 8px;
      background: #428ffc;
      opacity: 0.25;
      margin-top: -10px;
    }
  }
}
</style>
